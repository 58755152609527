import { reactive } from "vue";

let messages = reactive([]);

const guidGenerator = () =>  {
    const S4 = () => (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
};

const timeouts = [];

export default {
    get messages() {
        return messages;
    },

    add({title, message, type, level, timeout = 5000}) {
        const id = guidGenerator();

        if (level === "danger") {
            type = "error";
        }

        messages.push({id, title, message, type});
        this.addTimeout(id, timeout);
    },

    addTimeout(id, timeout = 5000) {
        timeouts[id] = setTimeout(() => this.remove({id}), timeout);
    },

    removeTimeout(id) {
        clearTimeout(timeouts[id]);
    },

    success(title, message, timeout) {
        this.add({ title, message, type: "success", timeout });
    },
    error(title, message, timeout) {
        this.add({ title, message, type: "error", timeout });
    },
    warning(title, message, timeout) {
        this.add({ title, message, type: "warning", timeout });
    },
    remove(message) {
        const index = messages.findIndex(({id}) => id === message.id);
        if (index === -1) { return; }
        messages.splice(index, 1);
    }
};
