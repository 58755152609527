import {shallowReactive} from "vue";

const state = shallowReactive({
    type: "alert",
    active: false,
    title: "",
    component: null,
    icon: null,
    componentProps: {},
});

const open = (props) => {
    state.active = true;
    Object.assign(state.componentProps, props);
};

const close = () => state.active = false;

export default {
    get state() {
        return state;
    },
    component(component) {
        state.component = component;
        return this;
    },
    title(title) {
        state.title = title;
        return this;
    },
    icon(icon) {
        state.icon = icon;
        return this;
    },
    open(props = {}) {
        return open(props);
    },
    close() {
        return close();
    }
};
