<template>
  <Link
    :href="href"
    :class="['group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']"
    :aria-current="active ? 'page' : undefined"
  >
    <div
      :class="active ? 'bg-gradient-to-tl from-purple-700 to-pink-500 shadow-soft-2xl bg-white bg-center stroke-0' : ''"
      class="group-hover:bg-gray-300 dark:group-hover:bg-zinc-700 p-2.5 rounded-lg transition-all"
    >
      <component
        :is="icon"
        :class="[iconClasses, 'h-7 w-7']"
        aria-hidden="true"
      />
    </div>
    <span class="mt-2 dark:text-white"><slot /></span>
  </Link>
</template>

<script setup>
import { computed } from "vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps(["href", "active", "icon"]);

const iconClasses = computed(() => props.active
    ? "text-white"
    : "group-hover:text-white"
);
</script>
