import { shallowReactive } from "vue";

const state = shallowReactive({
  active: false,
  title: "",
  callback: (_) => {},
  component: null,
  showTitle: true,
  size: "max-w-4xl",
  componentData: {}
});

export default {
  get state() {
    return state;
  },
  hideTitle() {
    state.showTitle = false;
    return this;
  },
  setSize(size) {
    state.size = size;
    return this;
  },
  show(title, component, componentData = {}) {
    state.title = title;
    state.component = component;
    state.componentData = componentData;
    state.active = true;
  },

  // implement a showPromise method that returns a promise that resolves when the callback method is called
  showPromise(title, component, componentData = {}) {
    this.show(title, component, componentData);

    return new Promise((resolve) => {
      state.callback = (data) => {
        resolve(data);
      };
    });
  },

  reset() {
    state.showTitle = true;
    state.size = "max-w-4xl";
  },
  close() {
    state.active = false;
    setTimeout(() => {
      if (!state.active) {
        state.component = null;
        this.reset();
      }
    }, 1000);
  }
};
