<template>
  <svg
    viewBox="0 0 233 233"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M158.359 198.044C190.435 170.144 210.155 130.688 213.217 88.2809L215.98 41.9012C216.263 37.4893 212.919 33.674 208.491 33.3757L193.952 32.418C169.365 30.7694 145.877 21.6473 126.644 6.26064L121.023 1.75455C118.087 -0.58485 113.91 -0.58485 110.974 1.75455L105.353 6.26064C86.1201 21.6473 62.6319 30.7694 38.0446 32.418L23.5058 33.3757C19.0939 33.674 15.734 37.4893 16.0166 41.9012L18.7799 88.2809C21.8415 130.688 41.5616 170.144 73.6381 198.044L110.723 230.293C113.753 232.915 118.244 232.915 121.274 230.293L158.359 198.044Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
</script>
