import Model from "./Model";
import {FingerPrintIcon, LanguageIcon, UserIcon} from "@heroicons/vue/24/outline";

export default class User extends Model {
    get icon() {
        switch (this.role) {
        case "admin":
            return FingerPrintIcon;
        case "user":
            return UserIcon;
        case "translator":
            return LanguageIcon;
        }
    }
    get canDelete() {
        return !!this.can.delete;
    }
    get canEdit() {
        return !!this.can.edit;
    }

    get canToggle() {
        return !!this.can.toggle;
    }
}
